<template>
  <div class="card">
    <div class="card-body">
      <form-control label='Name'>
        <input type="text"
               v-model="hcw_name"
               v-validate="'required'"
               :class="{'form-control': true, 'is-invalid': errors.has('hcw_name')}"
               placeholder="Name">
        <div class="invalid-feedback">
          Required
        </div>
      </form-control>
      <form-control label='Category'>
        <select
          v-model='hcw_category'
          class='form-control'>
          <option v-for='opt in hcw_categories' :key='opt' :value='opt'>{{ opt | snake_to_startcase }}</option>
        </select>
      </form-control>
    </div>
  </div>
</template>
<script>
import FormControlWrapper from '@/components/shared/form_group'

export default {
  name: 'HandHygieneObservation',
  data () {
    return {
      hcw_category: null,
      hcw_name: null,
      hcw_categories: [
        'Nursing',
        'Doctors',
        'Physiotherapy',
        'Houskeeping'
      ]
    }
  },
  components: {
    'form-control': FormControlWrapper
  }

}
</script>
