<template>
  <div class="card">
    <div class="card-body">
      <form-control label='Indication'>
        <select class='form-control'>
          <option v-for='opt in indications' :key='opt' :value='opt'>{{ opt | snake_to_startcase }}</option>
        </select>
      </form-control>
      <form-control label='Action'>
        <select class='form-control'>
          <option v-for='opt in hh_actions' :key='opt' :value='opt'>{{ opt | snake_to_startcase }}</option>
        </select>
      </form-control>
    </div>
  </div>
</template>
<script>
import FormControlWrapper from '@/components/shared/form_group'

export default {
  name: 'HandHygieneObservation',
  data () {
    return {
      indications: [
        'before_touching_patient',
        'before_clean_aseptic_procedure',
        'after_body_fluid_exposure_risk',
        'after_touching_patient',
        'after_touching_patient_surroundings'
      ],
      hh_actions: [
        'hand_rub',
        'hand_wash',
        'missed'
      ]
    }
  },
  components: {
    'form-control': FormControlWrapper
  }

}
</script>
