<template>
  <div>
    <h4>Culture Report Archive</h4>
    <detail-render :list="list_options" :list-data="report">
      <dd slot='isolates__dd' slot-scope='props'>
        <div v-for='isolate in props.item'>
          <table width=auto class='table table-sm table-bordered table-striped'>
            <tbody>
              <tr><td colspan=2><b>{{ isolate.organism }}</b></td></tr>
              <tr v-for='susceptibility_result in transformToOrderedSet(isolate.ast)'>
                <td>{{ susceptibility_result[0] }}</td>
                <td>{{ susceptibility_result[1] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </dd>
    </detail-render>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'
import detailRender from '@/components/shared/custom_widgets/detailRender'
import _ from 'lodash'

const getData = function (route) {
  return axios.get(`hmis_functions/cultures/${route.params.id}/`, axiosConfig)
}

const routerMixin = CreateRouterMixin(getData)

export default {
  name: 'CultureSearch',
  data () {
    return {
      report: {
        id: null,
        sample_date: null,
        patient__name: null,
        hospital: null,
        hospital_bed: null,
        isolates: []
      },
      list_options: [
        { label: 'Name', value: 'patient_name' },
        { label: 'PRN', value: 'hospital_id' },
        { label: 'Hospital', value: 'hospital' },
        { label: 'Location', value: 'hospital_bed' },
        { label: 'Lab ID', value: 'report_id' },
        { label: 'Sample ID', value: 'sample_id' },
        { label: 'Isolates', value: 'isolates' }
      ]
    }
  },
  mixins: [routerMixin],
  methods: {
    change_route (route) {
      this.$router.push(route)
    },
    setData (data) {
      this.report = data
    },
    transformToOrderedSet (obj) {
      return _(obj).toPairs().sortBy(0)
    }
  },
  created () {
  },
  watch: {
  },
  components: {
    'detail-render': detailRender
  }
}
</script>
