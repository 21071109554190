<template>
  <div>
    <h4>Culture Archive Search</h4>
    <div class="form-group row">
      <div class="col-lg-4">
        <label>Search Patient / PRN</label>
        <input type="text"
               class="form-control"
               placeholder="Search PRN or Name"
               v-model="search_term">
      </div>
    </div>
    <div class='grid'>
      <grid-table
        v-if='results.length'
        :table-data='results'
        :options='culture_grid.options'
        :columns="culture_grid.columns">
        >
        <template slot='hospital_id' slot-scope='props'>
          <router-link :to="{name:'CultureSearch', query: { hospital_id: props.row.hospital_id }}">{{ props.row.hospital_id }}</router-link>
        </template>
        <template slot='isolates' slot-scope='props'>
          <span v-if="props.row.isolates.length">
            <span class='isolate_string' v-for="isolate in props.row.isolates">{{ isolate.organism }}</span>
          </span>
          <span v-else>---</span>
        </template>
        <template slot='id' slot-scope='props'>
          <router-link :to="{name:'CultureDetail', params: { id: props.row.id }}">View</router-link>
        </template>

      </grid-table>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'
import GridTable from '@/components/shared/datatable/Grid'

const getData = function (route) {
  return axios.get('hmis_functions/cultures/',
    Object.assign({ params: route.query }, axiosConfig)
  )
}

const routerMixin = CreateRouterMixin(getData)

export default {
  name: 'CultureSearch',
  data () {
    return {
      search_term: '',
      results: [],
      result_count: 0,
      culture_grid: {
        options: {
          headings: {
            id: 'Report'
          },
          dateColumns: ['sample_date']
        },
        columns: ['sample_date', 'hospital_id', 'patient_name', 'specimen', 'isolates', 'id']
      }
    }
  },
  mixins: [routerMixin],
  methods: {
    change_route (route) {
      this.$router.push(route)
    },
    setData (data) {
      this.results = data.results
      this.result_count = data.count
    }
  },
  created () {
    this.debouncedChangeRoute = _.debounce(this.change_route, 500)
  },
  watch: {
    search_term: function (newSearch, oldSearch) {
      const route = { name: this.$route.name, query: {} }

      // A number usually means hospital ID
      if (newSearch.match(/\d{4,10}/)) { route.query.hospital_id = newSearch }

      // search syntax
      if (newSearch.match(/[A-Za-z]{4,}/)) {
        route.query.search = newSearch
      }
      this.debouncedChangeRoute(route)
    }
  },
  components: {
    'grid-table': GridTable
  }
}
</script>
<style scoped>
.isolate_string:after {
  content: ', ';
}
.isolate_string:last-child:after {
  content: '';
}

</style>
